module.exports = {
  selectLanguage: "Wähle deine Sprache",
  displayName: "Deutsch",
  categories: "Kategorien",
  read: "Lesen",
  price: "Preis",
  lastUpdate: "Letztes Update",
  free: "Jetzt kostenlos erhältlich",
  Software: "Software",
  "Machine Learning": "Maschinelles Lernen",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Stichworte",
  Home: "Startseite",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Kontakt Formular",
  "Cancel": "Stornieren",
  "Submit": "einreichen",
  "Contact Message": "Wir freuen uns, wenn Sie uns Feedback zu unserer Website geben.",
  "Flutter": "Flutter"
}
